import React from "react"
import HeroBlock from "../Core/HeroBlock"
import UspBlock from "../Core/USP/UspBlock"
import BlogCarousel from "../Core/BlogCarousel/BlogCarousel"
import LineDivider from "../Core/LineDivider"
import ProductHighlight from "../Core/ProductHighlight"
import Experts from "../Core/Experts"

const PageContent = ({ blocks }) => {
  let pageContents = []

  blocks.forEach((block, index) => {
    const type = block.__typename
    // console.log(block)

    if (type === "ContentfulHeroBlock") {
      pageContents.push(<HeroBlock {...block} key={index} />)
    }
    if (type === "ContentfulUspBlock") {
      pageContents.push(<UspBlock {...block} key={index} />)
    }
    if (type === "ContentfulProductHighlightBlock") {
      pageContents.push(<ProductHighlight {...block} key={index} />)
    }
    if (type === "ContentfulArticleCarouselBlock") {
      pageContents.push(<BlogCarousel {...block} key={index} limited />)
    }
    if (type === "ContentfulLineDivider") {
      pageContents.push(<LineDivider {...block} key={index} />)
    }
    if (type === "ContentfulExpertCarousel") {
      pageContents.push(<Experts {...block} key={index} />)
    }
  })

  return <>{pageContents}</>
}

export default PageContent
