import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import PageContent from "../components/LandingPage/PageContent"
import { graphql } from "gatsby"


const LandingPageTemplate = ({ location, data }) => {
  const path = location.pathname
  const { page } = data
  return (
    <Layout footer={false}>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
      />
      <PageContent blocks={page.blocks} />
    </Layout>
  )
}

export default LandingPageTemplate

export const landingPageQuery = graphql`
  query LandingPageQuery($slug: String!) {
    page: contentfulLandingPage(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulHeroBlock {
          id
          header
          hero {
            gatsbyImageData(width: 1920)
          }
          mobileHero {
            gatsbyImageData(width: 1202)
          }
        }
        ...uspBlockFragment
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
        ...expertCarouselFragment
        ... on ContentfulArticleCarouselBlock {
          id
          header
          subHeader {
            raw
          }
          filters {
            ... on ContentfulCategory {
              title
              categoryColor
            }
          }
          slides {
            __typename
            ... on ContentfulArticle {
              category {
                title
                categoryColor
              }
              slug
              title
              thumbnail {
                gatsbyImageData(width: 636, aspectRatio: 1)
              }
            }
          }
          ctaLabel
          ctaLink
        }
        ... on ContentfulLineDivider {
          id
          points {
            point
          }
        }
      }
    }
  }
`
